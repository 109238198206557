import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Date from '../../components/Post/date';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import PostsNavigation from '../../components/Post/posts-navigation';
import heroImg from '../../images/posts/006/search-tolerance.svg';
import * as styles from './006-search-with-typo-tolerance.module.css';
import SearchTolerance from '../../components/PostExtras/SearchTolerance/search-tolerance';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <HeroPost gradientFrom="#5E07F7" gradientTo="#052663" mdxType="HeroPost">
  <img src={heroImg} alt="Typo tolerance" height="256" />
    </HeroPost>
    <h1>{`Search with typo tolerance`}</h1>
    <Date date="January 02, 2021" dateMachine="2021-01-02" mdxType="Date" />
    <p>{`Everyone makes mistakes. That's why undo/redo is a must-have for interfaces where you work on something. That's why
we add extra padding around clickable elements for touch screens so it's easier to catch touches.
That's why Google tries to show some results even if what you typed is far from perfect.`}</p>
    <p>{`Users absolutely love that and they can't imagine software without `}<inlineCode parentName="p">{`ctrl+z`}</inlineCode>{` and looking at a "No results" page when they mistyped something.
It seems that the bar is high... but still, a `}<strong parentName="p">{`lot of software does only what is convenient for developers`}</strong>{` when it comes to
searching and showing results.`}</p>
    <h2>{`Examining the problem`}</h2>
    <p>{`Below we have a simple search that's going to work like filtering on the list. The list is short so it's going to be easy to
understand what's happening. In other words, we already have all elements on the screen but searching is going to help us in
finding `}<em parentName="p">{`that thing`}</em>{`.`}</p>
    <p>{`Please look at the list and type something you see there, misspell something or type something completely different:`}</p>
    <SearchTolerance mdxType="SearchTolerance" />
    <p>{`What we've just used here is a simple "contain" query. Or if you are familiar with SQL - we perform `}<inlineCode parentName="p">{`%LIKE%`}</inlineCode>{` here. Is it bad?
Well, it's okay. Better than strict comparison for sure. But it's not super friendly `}<strong parentName="p">{`because you have to be right`}</strong>{`.`}</p>
    <p>{`The hearth of this method is highlighted in the code below. We filter the list by checking if any fruit name contains the searched text.
There is `}<strong parentName="p">{`a bit of user-friendliness`}</strong>{` here - the search is case insensitive which is the desired behavior in most text searches done by users:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{7}",
        "{7}": true
      }}>{`const FRUITS = ['Apple', 'Banana', 'Blueberry', 'Cherries' /* etc... */];

function searchHandler(event) {
  const searchText = event.target.value.toLowerCase();

  const filteredFruits = FRUITS.filter((fruit) => {
    return fruit.toLowerCase().includes(searchText);
  });

  // render the list of \`filteredFruits\`
}
`}</code></pre>
    <h2>{`Introducing a tolerance`}</h2>
    <p>{`What about tolerating small mistakes aka typos? Let's try again. Search for fruits on the list but misspell them this time.
Maybe aple instead of apple?`}</p>
    <SearchTolerance tolerance={true} mdxType="SearchTolerance" />
    <p>{`Aple, I mean Apple is still on the list, right? Try bananna, blubery, cheries, peer, and so on. I must admit, the algorithm
is not auto-search friendly. The experience is much better with the `}<inlineCode parentName="p">{`[Search]`}</inlineCode>{` button because you won't see false friends here while typing.
But it's so much better for understanding how it works...`}</p>
    <p>{`Let's try `}<inlineCode parentName="p">{`pee`}</inlineCode>{` 🤭 for example. You should see Apple and Pear on the list. Both are pretty close matches according to the algorithm
we are using.`}</p>
    <h2>{`The algorithm`}</h2>
    <p>{`The algorithm used here is called `}<em parentName="p">{`Levenshtein distance`}</em>{`. I'm going to quote Wikipedia on this:`}</p>
    <div className="container">
  <blockquote className="blockquote">
    [...] the Levenshtein distance between two words is the minimum number of
    single-character edits (insertions, deletions or substitutions) required to
    change one word into the other.
  </blockquote>
    </div>
    <p>{`That's both a huge advantage and a problem sometimes. The shorter the names of searchable items are the worse for the algorithm.
Very short words like Pear, are favored when typing because the number of edits needed to "having a match" will be relatively
short comparing to a very long word that needs a lot of insertions.`}</p>
    <p>{`As stated in the definition, in the very heart of this algorithm `}<strong parentName="p">{`we calculate the distance`}</strong>{`. Then we decide if the distance
is something we accept - `}<strong parentName="p">{`so what's the minimum of edits that we accept?`}</strong>{`
Let's visualize that and see how far words are from your searched text:`}</p>
    <SearchTolerance tolerance={true} showDistance={true} mdxType="SearchTolerance" />
    <p>{`Let's go back to our embarrassing `}<inlineCode parentName="p">{`pee`}</inlineCode>{` example 🤭. What you should see on the screen is Apple (3) and Pear (2). How the distance
is measured? Please look below:`}</p>
    <div className={styles.distanceExamples}>
  <div className={styles.distanceExample}>
    <div className={styles.distanceSearchedText}>&nbsp;&nbsp;pee</div>
    <div>
      <span className={styles.distanceRemove}>ap</span>p
      <span className={styles.distanceRemove}>l</span>e
    </div>
    <div>12&nbsp;3&nbsp;</div>
  </div>
  <div className={styles.distanceExample}>
    <div className={styles.distanceSearchedText}>pee&nbsp;</div>
    <div>
      pe<span className={styles.distanceRemove}>ar</span>
    </div>
    <div>&nbsp;&nbsp;12</div>
  </div>
    </div>
    <p>{`In the case of Apple we need to perform 3 operations to get there from "pee": add `}<inlineCode parentName="p">{`A`}</inlineCode>{` and `}<inlineCode parentName="p">{`p`}</inlineCode>{` and change the first `}<inlineCode parentName="p">{`e`}</inlineCode>{` into `}<inlineCode parentName="p">{`l`}</inlineCode>{`.
When it comes to Pear, there are only 2 operations that have to be taken: change the second `}<inlineCode parentName="p">{`e`}</inlineCode>{` into `}<inlineCode parentName="p">{`a`}</inlineCode>{` and add `}<inlineCode parentName="p">{`r`}</inlineCode>{` at the end.
As you see it's easier to get Pear from the given input.`}</p>
    <p>{`So far we were just keeping the order of items as it was (alphabetical here). But in fact, Pear is closer to what we need than Apple and
that option should land as first on the list.`}</p>
    <p>{`Fear not, we are just going to sort it! Give it a try now:`}</p>
    <SearchTolerance tolerance={true} showDistance={true} sort={true} mdxType="SearchTolerance" />
    <h2>{`Implementation`}</h2>
    <p>{`So how it works? In a nutshell, we've just changed the searching/filtering algorithm (see highlighted lines).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "{8,9}",
        "{8,9}": true
      }}>{`const FRUITS = ['Apple', 'Banana', 'Blueberry', 'Cherries' /* etc... */];
const MIN_DISTANCE = 3;

function searchHandler(event) {
  const searchText = event.target.value.toLowerCase();

  const filteredFruits = FRUITS.filter((fruit) => {
    const distance = levenshtein(fruit.toLowerCase(), searchText);
    return distance <= MIN_DISTANCE;
  });

  // render the list of \`filteredFruits\`
}

function levenshtein(a, b) {
  // The Levenshtein's algorithm calculating the distance
}
`}</code></pre>
    <p>{`We compare distance by using Mr. Levenshtein's method and if the distance is higher than the minimal distance we accept we decide to
filter these entries out.`}</p>
    <p>{`When it comes to the algorithm itself, you may want to implement it on your own based on the definition on Wikipedia. But
if there is anything I know about computing, is that there are methods way faster than what comes to your mind first,
when you look at the mathematical equation.`}</p>
    <p><strong parentName="p">{`It's better to just use what is already there on the Internet.`}</strong>{` Here's `}<a parentName="p" {...{
        "href": "https://github.com/gustf/js-levenshtein/blob/master/index.js"
      }}>{`the implementation I used`}</a>{`.`}</p>
    <h2>{`Perfect tolerance (distance)`}</h2>
    <p>{`I couldn't find any equation for that but my best guess is that `}<strong parentName="p">{`the minimum tolerance (distance)`}</strong>{` that you should accept
should be `}<strong parentName="p">{`a bit smaller than the shortest word`}</strong>{` in your dataset. Otherwise, there is a possibility that this word will appear
too often.`}</p>
    <h2>{`Hybrid approach`}</h2>
    <p>{`If you haven't noticed yet, I use a combination of `}<inlineCode parentName="p">{`%LIKE%`}</inlineCode>{` match and Levenshtein's method. So we fall back to the latter method only
if we don't have typical matches. That's handy because the "exact" match is probably what users want. They probably don't care about
other variants of a searched text that could be considered as a "fixed" typo if they have exactly what they were looking for.`}</p>
    <h2>{`Is that a perfect method?`}</h2>
    <p>{`Well, it's not. Like most solutions, `}<strong parentName="p">{`it doesn't have to be perfect`}</strong>{`. If it adds more value than can bring confusion (because of false
friends in results sometimes) then it's useful.`}</p>
    <p>{`Levenshtein's method is one of many for a given subject. If you'd like to see more experiments like that, let me know.`}</p>
    <h2>{`Bonus: Does Google do the same?`}</h2>
    <p>{`Nope. Their `}<em parentName="p">{`"Did you mean?"`}</em>{` functionality in search is very different from this. As far as I know, they based it on us (the users)
who correct queries when we can't find anything useful because of typos.
This way, with the unbelievable amount of data they possess, they can teach the algorithm what's the best guess for given "typos".
It's far more sophisticated but it can be super-efficient for long queries.`}</p>
    <p>{`Anyway, for our `}<strong parentName="p">{`front-end needs`}</strong>{` and as a first attempt to helping users with typos in search I think we are `}<strong parentName="p">{`good enough`}</strong>{` with
Levenshtein method. `}<strong parentName="p">{`What do you think?`}</strong></p>
    <div className="container mt-40 mb-55">
  <TwitterBox threadLink="https://twitter.com/tomekdev_/status/1345295019213811714" mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Useful setup that I always use when starting a new project" prevLink="/posts/powerful-start" nextTitle="Highlight text in JavaScript" nextLink="/posts/highlight-text-in-javascript" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      